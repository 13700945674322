import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateUserAction } from '@/redux/actions/auth';
import {
  updateUser,
  TUpdateUserResponse,
  checkAccessToken,
  TAuthCheckTokenResponse,
  TAuthRefreshTokenResponse,
  refreshToken,
} from '@/services/api/auth';
import { handleAPIError } from '@/utils/handle-api-error';
import Helpers from '@/services/helpers';

// FUNCTION

export function* updateUserSaga(action: ActionType<typeof updateUserAction.request>): Generator {
  const { materials, userId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(updateUser, materials, userId);
    const updateUserResponse: TUpdateUserResponse = response as TUpdateUserResponse;
    yield put(updateUserAction.success(updateUserResponse));
    successCallback?.(updateUserResponse);
  } catch (err) {
    yield put(updateUserAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateUserInfoError');
  }
}
