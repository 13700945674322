import ApiService from '@/services/api';
import Helpers from '@/services/helpers';

// TYPES
export type TAuthRefreshTokenResponse = any;

// FUNCTION
const endpoint = `/api/token/refresh/`;
export const refreshToken = async (): Promise<TAuthRefreshTokenResponse> => {
  const response = await ApiService.post(endpoint, { refresh: Helpers.getRefreshToken() });
  return response;
};
