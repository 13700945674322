import { createActionCreator } from 'deox';
import { TChangePasswordBody, TChangePasswordResponse } from '@/services/api/auth';
import message from '@/utils/message';

// CONSTANTS

export enum EChangePasswordAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED',
}

// TYPES

export type TChangePasswordRequest = {
  type: EChangePasswordAction.CHANGE_PASSWORD_REQUEST;
  payload: {
    materials: TChangePasswordBody;
    userId: number;
    successCallback?: (response: TChangePasswordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangePasswordSuccess = {
  type: EChangePasswordAction.CHANGE_PASSWORD_SUCCESS;
  payload: { response: TChangePasswordResponse };
};

export type TChangePasswordFailed = { type: EChangePasswordAction.CHANGE_PASSWORD_FAILED };

// FUNCTION

export const changePasswordAction = {
  request: createActionCreator(
    EChangePasswordAction.CHANGE_PASSWORD_REQUEST,
    (resolve) =>
      (
        materials: TChangePasswordBody,
        userId: number,
        successCallback?: (response: TChangePasswordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangePasswordRequest => {
        return resolve({ materials, userId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EChangePasswordAction.CHANGE_PASSWORD_SUCCESS,
    (resolve) =>
      (response: TChangePasswordResponse): TChangePasswordSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EChangePasswordAction.CHANGE_PASSWORD_FAILED,
    (resolve) =>
      (error: any): TChangePasswordFailed => {
        // message.error('CHANGE_PASSWORD_FAILED Action:', error?.data.status_message);
        return resolve({ error });
      },
  ),
};
