import { createActionCreator } from 'deox';

import { TCopyProjectBody, TCopyProjectResponse } from '@/services/api/project/copy-project';

// CONSTANTS

export enum ECopyProjectAction {
  COPY_PROJECT = 'COPY_PROJECT',
  COPY_PROJECT_REQUEST = 'COPY_PROJECT_REQUEST',
  COPY_PROJECT_SUCCESS = 'COPY_PROJECT_SUCCESS',
  COPY_PROJECT_FAILED = 'COPY_PROJECT_FAILED',
}

// TYPES

export type TCopyProjectRequest = {
  type: ECopyProjectAction.COPY_PROJECT_REQUEST;
  payload: {
    materials: TCopyProjectBody;
    companyId: number;
    successCallback?: (response: TCopyProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCopyProjectSuccess = {
  type: ECopyProjectAction.COPY_PROJECT_SUCCESS;
  payload: { response: TCopyProjectResponse };
};

export type TCopyProjectFailed = { type: ECopyProjectAction.COPY_PROJECT_FAILED };

// FUNCTION

export const copyProjectAction = {
  request: createActionCreator(
    ECopyProjectAction.COPY_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TCopyProjectBody,
        companyId: number,
        successCallback?: (response: TCopyProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCopyProjectRequest =>
        resolve({ materials, companyId, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECopyProjectAction.COPY_PROJECT_SUCCESS,
    (resolve) =>
      (response: TCopyProjectResponse): TCopyProjectSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECopyProjectAction.COPY_PROJECT_FAILED,
    (resolve) =>
      (error: any): TCopyProjectFailed =>
        resolve({ error }),
  ),
};
