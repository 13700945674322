import { createActionCreator } from 'deox';

import { TGetListEmployeeInsideResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListEmployeeInsideAction {
  GET_LISTEMPLOYEEINSIDE = 'GET_LISTEMPLOYEEINSIDE',
  GET_LISTEMPLOYEEINSIDE_REQUEST = 'GET_LISTEMPLOYEEINSIDE_REQUEST',
  GET_LISTEMPLOYEEINSIDE_SUCCESS = 'GET_LISTEMPLOYEEINSIDE_SUCCESS',
  GET_LISTEMPLOYEEINSIDE_FAILED = 'GET_LISTEMPLOYEEINSIDE_FAILED',
}

// TYPES

export type TGetListEmployeeInsideRequest = {
  type: EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TGetListEmployeeInsideResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListEmployeeInsideSuccess = {
  type: EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_SUCCESS;
  payload: { response: TGetListEmployeeInsideResponse };
};

export type TGetListEmployeeInsideFailed = { type: EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_FAILED };

// FUNCTION

export const getListEmployeeInsideAction = {
  request: createActionCreator(
    EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TGetListEmployeeInsideResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListEmployeeInsideRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_SUCCESS,
    (resolve) =>
      (response: TGetListEmployeeInsideResponse): TGetListEmployeeInsideSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListEmployeeInsideAction.GET_LISTEMPLOYEEINSIDE_FAILED,
    (resolve) =>
      (error: any): TGetListEmployeeInsideFailed => {
        message.error('GET_LISTEMPLOYEEINSIDE_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
