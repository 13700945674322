import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changePasswordAction } from '@/redux/actions/auth';
import {
  changePassword,
  checkAccessToken,
  refreshToken,
  TAuthCheckTokenResponse,
  TAuthRefreshTokenResponse,
  TChangePasswordResponse,
} from '@/services/api/auth';
import { handleAPIError } from '@/utils/handle-api-error';
import Helpers from '@/services/helpers';

// FUNCTION

export function* changePasswordSaga(action: ActionType<typeof changePasswordAction.request>): Generator {
  const { materials, userId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) Helpers.storeAccessToken(refreshResponse.data.access_token);
    }
    const response = yield call(changePassword, materials, userId);
    const changePasswordResponse: TChangePasswordResponse = response as TChangePasswordResponse;
    yield put(changePasswordAction.success(changePasswordResponse));
    successCallback?.(changePasswordResponse);
  } catch (err) {
    yield put(changePasswordAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.changePasswordError');
  }
}
