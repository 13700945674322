import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TChangePasswordBody = {
  old_password: string;
  new_password: string;
};
export type TChangePasswordResponse = TCommonResponse;

// FUNCTION
export const changePassword = async (
  requestBody: TChangePasswordBody,
  userId: number,
): Promise<TChangePasswordResponse> => {
  const endpoint = `/v1/user/${userId}/change-password/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
