import { createActionCreator } from 'deox';

// CONSTANTS

export enum ESaveEditAction {
  SAVE_EDIT = 'SAVE_EDIT',
  SAVE_EDIT_REQUEST = 'SAVE_EDIT_REQUEST',
  SAVE_EDIT_SUCCESS = 'SAVE_EDIT_SUCCESS',
  SAVE_EDIT_FAILED = 'SAVE_EDIT_FAILED',
}

// TYPES

export type TSaveEditRequest = {
  type: ESaveEditAction.SAVE_EDIT_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TSaveEditSuccess = {
  type: ESaveEditAction.SAVE_EDIT_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TSaveEditFailed = {
  type: ESaveEditAction.SAVE_EDIT_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const saveEditAction = {
  request: createActionCreator(
    ESaveEditAction.SAVE_EDIT_REQUEST,
    (resolve) =>
      (payload: { status: boolean }): TSaveEditRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    ESaveEditAction.SAVE_EDIT_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TSaveEditSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    ESaveEditAction.SAVE_EDIT_FAILED,
    (resolve) => (/* Pass your payload data here */): TSaveEditFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
