import { createActionCreator } from 'deox';
import { TUpdateUserBody, TUpdateUserResponse } from '@/services/api/auth';

// CONSTANTS

export enum EUpdateUserAction {
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
}

// TYPES

export type TUpdateUserRequest = {
  type: EUpdateUserAction.UPDATE_USER_REQUEST;
  payload: {
    materials: TUpdateUserBody;
    userId: number;
    successCallback?: (response: TUpdateUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateUserSuccess = {
  type: EUpdateUserAction.UPDATE_USER_SUCCESS;
  payload: { response: TUpdateUserResponse };
};

export type TUpdateUserFailed = { type: EUpdateUserAction.UPDATE_USER_FAILED };

// FUNCTION

export const updateUserAction = {
  request: createActionCreator(
    EUpdateUserAction.UPDATE_USER_REQUEST,
    (resolve) =>
      (
        materials: TUpdateUserBody,
        userId: number,
        successCallback?: (response: TUpdateUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateUserRequest => {
        return resolve({ materials, userId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EUpdateUserAction.UPDATE_USER_SUCCESS,
    (resolve) =>
      (response: TUpdateUserResponse): TUpdateUserSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(EUpdateUserAction.UPDATE_USER_FAILED, (resolve) => (error: any): TUpdateUserFailed => {
    // message.error('UPDATE_USER_FAILED Action:', error?.data.status_message);
    return resolve({ error });
  }),
};
