import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { assignProjectListAction } from '@/redux/actions/employee';
import { assignProjectList, TAssignProjectListResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

export function* assignProjectListSaga(action: ActionType<typeof assignProjectListAction.request>): Generator {
  const { materials, companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(assignProjectList, materials, companyId, employeeId);
    const assignProjectListResponse: TAssignProjectListResponse = response as TAssignProjectListResponse;
    yield put(assignProjectListAction.success(assignProjectListResponse));
    successCallback?.(assignProjectListResponse);
  } catch (err) {
    yield put(assignProjectListAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.assignProjectsEmployeeError');
  }
}
