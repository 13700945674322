import ApiService from '@/services/api';

// TYPES
export type TUpdateUserBody = {
  full_name: string;
  phone: string;
};
export type TUpdateUserResponse = unknown;

// FUNCTION
export const updateUser = async (requestBody: TUpdateUserBody, userId: number): Promise<TUpdateUserResponse> => {
  const endpoint = `/v1/user/${userId}/update-info/`;
  const response = await ApiService.put(endpoint, requestBody);
  return response;
};
