import { createActionCreator } from 'deox';
import { TVerifyOtpBody, TVerifyOtpResponse } from '@/services/api/auth/verify-otp';
import message from '@/utils/message';

// CONSTANTS

export enum EVerifyOtpAction {
  VERIFY_OTP = 'VERIFY_OTP',
  VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED',
}

// TYPES

export type TVerifyOtpRequest = {
  type: EVerifyOtpAction.VERIFY_OTP_REQUEST;
  payload: {
    materials: TVerifyOtpBody;
    successCallback?: (response: TVerifyOtpResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVerifyOtpSuccess = {
  type: EVerifyOtpAction.VERIFY_OTP_SUCCESS;
  payload: { response: TVerifyOtpResponse };
};

export type TVerifyOtpFailed = { type: EVerifyOtpAction.VERIFY_OTP_FAILED };

// FUNCTION

export const verifyOtpAction = {
  request: createActionCreator(
    EVerifyOtpAction.VERIFY_OTP_REQUEST,
    (resolve) =>
      (
        materials: TVerifyOtpBody,
        successCallback?: (response: TVerifyOtpResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVerifyOtpRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EVerifyOtpAction.VERIFY_OTP_SUCCESS,
    (resolve) =>
      (response: TVerifyOtpResponse): TVerifyOtpSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(EVerifyOtpAction.VERIFY_OTP_FAILED, (resolve) => (error: any): TVerifyOtpFailed => {
    message.error(error?.message);
    return resolve({ error });
  }),
};
