import { authLoginAction } from '@/redux/actions'; // Import userActions
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { authLogin } from '@/services/api';
import { TUserInfo } from '@/services/api/auth/get-user-info';
import Helpers from '@/services/helpers';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
interface IAuthLoginResponse {
  status: number;
  data: {
    access_token: string;
    refresh_token: string;
    refresh_token_time: string;
    user: TUserInfo;
  };
}

export function* authLoginSaga(action: ActionType<typeof authLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;

  const checkLogin = (response: any): number => {
    const refreshTokenTimeMs = response.data.refresh_token_time * 24 * 60 * 60;
    return refreshTokenTimeMs;
  };

  try {
    const response = yield call(authLogin, materials);
    const authLoginResponse: IAuthLoginResponse = response as IAuthLoginResponse;
    if (authLoginResponse.status === 200) {
      const expiredTime = checkLogin(response);
      Helpers.storeAccessToken(authLoginResponse.data.access_token);
      Helpers.storeRefreshToken(authLoginResponse.data.refresh_token, expiredTime);
      Helpers.storeUserInfo(authLoginResponse.data.user as TUserInfo);
      yield put(authLoginAction.success(authLoginResponse));
    }
    successCallback?.(authLoginResponse);
  } catch (err) {
    yield put(authLoginAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.loginEmailError');
  }
}
