import { createActionCreator } from 'deox';

import { TProjectExportExcelResponse } from '@/services/api/project-export';
import message from '@/utils/message';

// CONSTANTS

export enum EProjectExportExcelAction {
  PROJECT_EXPORT_EXCEL = 'PROJECT_EXPORT_EXCEL',
  PROJECT_EXPORT_EXCEL_REQUEST = 'PROJECT_EXPORT_EXCEL_REQUEST',
  PROJECT_EXPORT_EXCEL_SUCCESS = 'PROJECT_EXPORT_EXCEL_SUCCESS',
  PROJECT_EXPORT_EXCEL_FAILED = 'PROJECT_EXPORT_EXCEL_FAILED',
}

// TYPES

export type TProjectExportExcelRequest = {
  type: EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TProjectExportExcelResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TProjectExportExcelSuccess = {
  type: EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_SUCCESS;
  payload: { response: TProjectExportExcelResponse };
};

export type TProjectExportExcelFailed = { type: EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_FAILED };

// FUNCTION

export const projectExportExcelAction = {
  request: createActionCreator(
    EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TProjectExportExcelResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TProjectExportExcelRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_SUCCESS,
    (resolve) =>
      (response: TProjectExportExcelResponse): TProjectExportExcelSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EProjectExportExcelAction.PROJECT_EXPORT_EXCEL_FAILED,
    (resolve) =>
      (error: any): TProjectExportExcelFailed => {
        message.error('PROJECT_EXPORT_EXCEL_FAILED Action:', error.data.status_message);
        return resolve({ error });
      },
  ),
};
