import ApiService from '@/services/api';

// TYPES
export type TProjectExportXmlResponse = unknown;

// FUNCTIONS
export const projectExportXml = async (companyId: number, projectId: number): Promise<TProjectExportXmlResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/xml/`;
  const response = await ApiService.get(endpoint);
  return response;
};
