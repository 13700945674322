import ApiService from '@/services/api';

// TYPES
export type TAddListEmployeeBody = {
  full_name: string;
  email: string;
  phone: string;
  password: string;
  role: string;
};
export type TAddListEmployeeResponse = unknown;

// FUNCTION
export const addListEmployee = async (
  requestBody: TAddListEmployeeBody,
  companyId: number,
): Promise<TAddListEmployeeResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/add-employee/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
