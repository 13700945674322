import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';
import { getUserInfoAction } from '@/redux/actions';
import { useDispatch } from 'react-redux';
import { handleAPIError } from '@/utils/handle-api-error';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Home = lazy(() => retryLoadComponent(() => import('@/pages/Home')));
const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const Project = lazy(() => retryLoadComponent(() => import('@/pages/Project')));
const Account = lazy(() => retryLoadComponent(() => import('@/pages/Account')));
// const ProjectDetail = lazy(() => retryLoadComponent(() => import('@/pages/ProjectDetail')));

export const LayoutPaths = {
  Guest: '/',
  Auth: '/',
  Project: '/projects',
  Admin: '/admin',
};

export const ModulePaths = {};

export const Paths = {
  Home: '/',
  Login: '/login',
  Project: '/projects',
  Account: '/account',
  Rest: '*',
};

export const Pages = {
  Home,
  Login,
  Project,
  Account,
};

interface IRouteProps extends RouteComponentProps {
  component: any;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};

const RouteWrapper = ({ component: Component, ...rest }): any => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userUpdateState, setUserUpdateState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getUserInfoAction.request((response: any): any => {
          if (response.status === 200) {
            setUserUpdateState((prevState) => !prevState);
          } else handleAPIError(response, 'Error.getUserInfoError');
        }),
      );
    }, 2000);
  }, [dispatch, location]);

  return <Component location={location} userUpdateState={userUpdateState} {...rest} />;
};

const DelayedSuspense: React.FC<any> = ({ children, delay = 500 }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsReady(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!isReady) {
    return <Loading />;
  }

  return <>{children}</>;
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const refreshToken = Helpers.getRefreshToken();
  const dispatch = useDispatch();
  if (!refreshToken) return <Redirect noThrow from={Paths.Rest} to={Paths.Login} />;
  else {
    setTimeout(() => {
      dispatch(
        getUserInfoAction.request((response: any): any => {
          if (response.status !== 200) handleAPIError(response, 'Error.getUserInfoError');
        }),
      );
    }, 2000);
    return (
      <DelayedSuspense delay={2000}>
        <RouteWrapper {...rest} component={Component} />
      </DelayedSuspense>
    );
  }
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const refreshToken = Helpers.getRefreshToken();
  const dispatch = useDispatch();
  if (refreshToken) {
    dispatch(
      getUserInfoAction.request((response: any): any => {
        if (response.status !== 200) handleAPIError(response, 'Error.getUserInfoError');
      }),
    );
    return <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Project} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};
