export const stepsMainEN = (): any => [
  {
    name: 'Main-en',
    selector: '#btn-setting',
    content: 'Click Settings.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
      // Simulate a click to open the modal if it is not already open
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab1"]',
    content: 'Click on External Load Capacity.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('left');
        reactour[i].classList.remove('top');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Pname',
    content: (
      <>
        <div>
          <p>
            Select a number for the load point / pile number. A load point describes a point, e.g. below a column, at
            which vertical loads act on any number of piles. Enter a name for the load point here. If there is only one
            pile at this load point, it is advisable to enter the pile number.
          </p>
          <p>The load point designation must be unique for each line of the table.</p>
        </div>
      </>
    ),
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlTyp',
    content:
      'Specify the pile type. (bp: bored pile, f: Fundex, v: VGS (Vroom), a: Atlas, c: Precast concrete driven pile, R: Precast driven pile as a closed steel tube).',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlAnzahl',
    content:
      'Enter the planned number of piles at the load point, e.g. 4 piles under a column.  The minimum number is 1.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlAchsAbstandxD',
    content: (
      <>
        <div>
          <p>
            Enter the pile centre distance (measured in plan) as a multiple of the pile diameter, e.g. 3. Up to a pile
            number of 5 piles, the reduction in resistance after the large replacement pile is EA piles.
          </p>
          <p>
            If there are more than 5 piles, no reduction is applied by the programme. In this case, the user must carry
            out the reduction to the calculated load-bearing capacity himself if the pile spacing is not large enough to
            dispense with a reduction. In Germany, a distance of ≥ 3*pile diameter is usual. The calculated reduction
            can be taken into account via the column shell fraction [%].
          </p>
        </div>
      </>
    ),
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AEHoehe',
    content:
      'Specify the global elevation of the working level / drilling level from which the piles are to be installed. This should generally be above the upper edge of the connecting reinforcement. A usual value is 50 cm.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#SollPfahlOberKante',
    content: 'Enter the global elevation of the planned upper edge of the pile. In Germany usually NHN heights.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Rechtswert',
    content:
      'Enter the coordinate of the load point in the floor plan in the X direction. An entry is only required if pile toe stepping is to be taken into account.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Hochwert',
    content:
      'Enter the coordinate of the load point in the floor plan in the Y direction. An entry is only required if pile toe stepping is to be taken into account.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#SollDurchmesser',
    content: 'Enter the planned diameter for round pile cross-sections or the planned edge length for square piles.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeCharakteristischeLastZ',
    content:
      'Specify the vertical characteristic load acting at the load point. Positive values = pressure. This load is used for the settlement calculation.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeDesignLastZ',
    content:
      'Specify the vertical design load acting at the load point. Positive values = pressure. This load is used to calculate the required pile length or to verify the external load-bearing capacity.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#BodenProfil',
    content:
      'Select the soil profile to be considered at the load point. To do this, you must have created one or more soil profiles in the soil profile table.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#einzelMindestEindringung',
    content:
      'The minimum penetration describes the minimum length that the pile must penetrate into a soil layer defined as penetration-relevant, e.g. 1 m into a rock layer. Whether a layer is penetration-relevant can be defined in the soil profile table. If there is no corresponding requirement, the value can be set to 0. This is not the minimum penetration into a load-bearing layer that must be complied with in order to apply the empirical values from the EA piles, which are taken into account in parallel.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#einzelzulaessigeSetzungCm',
    content:
      'Enter the permissible settlement for this load point. If the permissible settlement specified here in the line differs from the permissible settlement specified in the calculation settings for this project, the smaller value of the two is used by the programme.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#prozentualerMantelAnteil',
    content:
      'Here you can enter the percentage of skin friction to be taken into account in the calculation. An increase, e.g. for partial displacement piles, is just as possible as a reduction, e.g. to take into account a group effect. The entry in the table row is multiplied by the value of the skin friction increase from the global calculation settings, e.g. 0.9 * 1.1.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeCharakteristischeMinLastZ',
    content: 'Comming soon',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeDesignMinLastZ',
    content: 'Comming soon',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab2"]',
    content: 'Click on soil profile.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
];

export const stepsMainDE = (): any => [
  {
    name: 'Main-de',
    selector: '#btn-setting',
    content: 'Klicken Sie auf Einstellungen.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
      // Simulate a click to open the modal if it is not already open
    },
  },
  {
    selector: '.Work-Tabs [data-node-key="tab1"]',
    content: 'Klicken Sie auf Äußere Tragfähigkeit.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Pname',
    content: (
      <>
        <div>
          <p>
            Wählen Sie eine Nummer für den Lastpunkt / die Pfahlnummer. Ein Lastpunkt beschreibt einen Punkt z.B.
            unterhalb einer Stütze an dem vertikale Lasten auf eine beliebige Anzahl von Pfählen wirken. Geben Sie hier
            eine Bezeichnung für den Lastpunkt ein. Wenn sich nur ein Pfahl an diesem Lastpunkt befindet, empfiehlt sich
            die Eingabe der Pfahlnummer.
          </p>
          <p>Die Lastpunktbezeichnung muss je Zeile der Tabelle eindeutig sein.</p>
        </div>
      </>
    ),
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlTyp',
    content:
      'Geben Sie den Pfahltyp an. (bp: Bohrpfahl, f: Fundex, v: VGS (Vroom), a: Atlas, c: Fertigbetonrammpfahl, R: Fertigrammpfahl als geschlossenes Stahlrohr).',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlAnzahl',
    content:
      'Geben Sie die geplante Pfahlanzahl an dem Lastpunkt an z.B. 4 Pfähle unter einer Stütze.  Mindestanzahl ist 1.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#PfahlAchsAbstandxD',
    content: (
      <>
        <div>
          <p>
            Geben Sie den Pfahl Achsabstand (im Grundriss gemessen) als ein Vielfaches des Pfahldurchmessers an z.B. 3.
            Bis zu einer Pfahlanzahl von 5 Pfählen ist dabei die Widerstandsabminderung nach dem großen Ersatzpfahl EA
            Pfähle.
          </p>
          <p>
            Bei mehr als 5 Pfählen wird, keine programmseitig keine Abminderung vorgenommen. Der Nutzer muss die
            Abminderung auf die berechnete Tragfähigkeit in diesem Fall selbst vornehmen, wenn der Pfahlabstand nicht
            ausreichend groß ist, dass auf eine Abminderung verzichtet werden kann. Üblich ist in Deutschland ein
            Abstand von ≥ 3*Pfahldurchmesser. Die ermittelte Abminderung kann über die Spalte Mantelanteil [%]
            berücksichtigt werden.
          </p>
        </div>
      </>
    ),
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AEHoehe',
    content:
      'Geben Sie die globale Höhenkote der Arbeitsebene / Bohrebene an, von welcher aus die Pfähle hergestellt werden. Dies sollte i.d.R. oberhalb der Oberkante der Anschlussbewehrung liegen. Ein üblicher Wert sind 50 cm.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#SollPfahlOberKante',
    content: 'Geben Sie die globale Höhekote der geplanten Pfahloberkante ein. In Deutschland üblicherweise NHN Höhen.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Rechtswert',
    content:
      'Geben Sie die Koordinate des Lastpunkts im Grundriss in X-Richtung an. Eine Eingabe ist nur erforderlich, wenn eine Pfahlfußabtreppung berücksichtigt werden soll.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#Hochwert',
    content:
      'Geben Sie die Koordinate des Lastpunkts im Grundriss in Y-Richtung an. Eine Eingabe ist nur erforderlich, wenn eine Pfahlfußabtreppung berücksichtigt werden soll.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#SollDurchmesser',
    content:
      'Geben Sie den geplanten Durchmesser für runde Pfahlquerschnitte bzw. Geplante Kantenlänge für quadratische Pfähle die an.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeCharakteristischeLastZ',
    content:
      'Geben Sie die am Lastpunkt wirkende vertikale charakteristische Last an. Positive Werte = Druck. Diese Last wird für die Setzungsberechnung verwendet.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#AlternativeDesignLastZ',
    content:
      'Geben Sie die am Lastpunkt wirkende vertikale Designlast an. Positive Werte = Druck. Diese Last wird für die Berechnung der notwendigen Pfahllänge bzw. der Nachweis der äußeren Tragfähigkeit verwendet.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#BodenProfil',
    content:
      'Wählen Sie das Bodenprofil aus, das am Lastpunkt berücksichtigt werden soll. Die Bodenprofile werden in der Tabelle Bodenprofil angelegt.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#einzelMindestEindringung',
    content:
      'Die Mindesteindringung beschreibt die Mindestlänge, welche der Pfahl in eine als eindringungsrelevant definierte Bodenschicht eindringen muss, z.B. 1 m in eine Felsschicht. Ob eine Schicht eindringungsrelevant ist, kann in der Tabelle Bodenprofil festgelegt werden. Besteht keine entsprechende Forderung, kann der Wert zu 0 gesetzt werden. Es handelt sich dabei nicht um die einzuhaltenden Mindesteinbindung in eine tragende Schicht, welche zur Anwendung der Erfahrungswerte aus der EA-Pfähle einzuhalten sind, welche parallel berücksichtigt wird.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#einzelzulaessigeSetzungCm',
    content:
      'Geben Sie die zulässige Setzung für diesen Lastpunkt an. Ist die hier in der Zeile angegebene zulässige Setzung abweichend von der bei den Berechnungseistellungen für dieses Projekt angegebene zulässigen Setzung so wird der kleinere Wert der beiden vom Programm angesetzt.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  {
    selector: '.tour#prozentualerMantelAnteil',
    content:
      'Hier können Sie den prozentualen Anteil der Mantelreibung eingeben, welcher bei der Berechnung berücksichtigt wird. Eine Erhöhung wie z.B. bei Teilverdrängungspfählen ist genauso möglich wie eine Abminderung z.B. zur Berücksichtigung einer Gruppenwirkung. Die Eingabe in der Tabellenzeile wird mit dem Wert der Mantelreibungserhöhung aus den globalen Berechnungseinstellungen multipliziert z.B. 0,9 * 1,1.',
    position: 'left',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('right');
        reactour[i].classList.add('left');
        reactour[i].classList.remove('bottom');
      }
    },
  },
  // TODO: This is the guided tours defination of 2 columns AlternativeCharakteristischeMinLast and AlternativeDesignMinLastZ
  // {
  //   selector: '.tour#AlternativeCharakteristischeMinLastZ',
  //   content: 'Demnächst',
  //   position: 'left',
  //   action: (node): any => {
  //     const reactour = document.getElementsByClassName('reactour__popover');
  //     for (let i = 0; i < reactour.length; i++) {
  //       reactour[i].classList.remove('top');
  //       reactour[i].classList.remove('right');
  //       reactour[i].classList.add('left');
  //       reactour[i].classList.remove('bottom');
  //     }
  //   },
  // },
  // {
  //   selector: '.tour#AlternativeDesignMinLastZ',
  //   content: 'Demnächst',
  //   position: 'left',
  //   action: (node): any => {
  //     const reactour = document.getElementsByClassName('reactour__popover');
  //     for (let i = 0; i < reactour.length; i++) {
  //       reactour[i].classList.remove('top');
  //       reactour[i].classList.remove('right');
  //       reactour[i].classList.add('left');
  //       reactour[i].classList.remove('bottom');
  //     }
  //   },
  // },
  {
    selector: '.Work-Tabs [data-node-key="tab2"]',
    content: 'Klicken Sie auf Bodenprofil.',
    position: 'right',
    action: (node): any => {
      const reactour = document.getElementsByClassName('reactour__popover');
      for (let i = 0; i < reactour.length; i++) {
        reactour[i].classList.remove('top');
        reactour[i].classList.remove('left');
        reactour[i].classList.add('right');
        reactour[i].classList.remove('bottom');
      }
    },
  },
];
