import ApiService from '@/services/api';

// TYPES
export type TAuthLogoutBody = {
  refresh_token: string;
};
export type TAuthLogoutResponse = unknown;

// FUNCTION
const endpoint = `/v1/auth/logout/`;
export const authLogout = async (requestBody: TAuthLogoutBody): Promise<TAuthLogoutResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
