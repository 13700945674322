import { createActionCreator } from 'deox';
import { TProjectImportFileBody, TProjectImportFileResponse } from '@/services/api/project-import';
import message from '@/utils/message';

// CONSTANTS

export enum EProjectImportFileAction {
  PROJECT_IMPORT_FILE = 'PROJECT_IMPORT_FILE',
  PROJECT_IMPORT_FILE_REQUEST = 'PROJECT_IMPORT_FILE_REQUEST',
  PROJECT_IMPORT_FILE_SUCCESS = 'PROJECT_IMPORT_FILE_SUCCESS',
  PROJECT_IMPORT_FILE_FAILED = 'PROJECT_IMPORT_FILE_FAILED',
}

// TYPES

export type TProjectImportFileRequest = {
  type: EProjectImportFileAction.PROJECT_IMPORT_FILE_REQUEST;
  payload: {
    materials: TProjectImportFileBody;
    companyId: number;
    projectId: number;
    successCallback?: (response: TProjectImportFileResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TProjectImportFileSuccess = {
  type: EProjectImportFileAction.PROJECT_IMPORT_FILE_SUCCESS;
  payload: { response: TProjectImportFileResponse };
};

export type TProjectImportFileFailed = { type: EProjectImportFileAction.PROJECT_IMPORT_FILE_FAILED };

// FUNCTION

export const projectImportFileAction = {
  request: createActionCreator(
    EProjectImportFileAction.PROJECT_IMPORT_FILE_REQUEST,
    (resolve) =>
      (
        materials: TProjectImportFileBody,
        companyId: number,
        projectId: number,
        successCallback?: (response: TProjectImportFileResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TProjectImportFileRequest => {
        return resolve({ materials, companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EProjectImportFileAction.PROJECT_IMPORT_FILE_SUCCESS,
    (resolve) =>
      (response: TProjectImportFileResponse): TProjectImportFileSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EProjectImportFileAction.PROJECT_IMPORT_FILE_FAILED,
    (resolve) =>
      (error: any): TProjectImportFileFailed => {
        message.error('PROJECT_IMPORT_FILE_FAILED Action:', error?.data);

        return resolve({ error });
      },
  ),
};
