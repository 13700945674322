import ApiService from '@/services/api';

// TYPES
export type TUpdateProfileEmployeeResponse = unknown;
export type TUpdateProfileEmployeeBody = {
  full_name: string;
  email: string;
  phone: string;
  password?: string;
};

// FUNCTION
export const updateProfileEmployee = async (
  requestBody: TUpdateProfileEmployeeBody,
  companyId: number,
  employeeId: number,
): Promise<TUpdateProfileEmployeeResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/${employeeId}/update-info/`;
  const response = await ApiService.put(endpoint, requestBody);
  return response;
};
