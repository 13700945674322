import ApiService from '@/services/api';

// TYPES
export type TGetProjectDetailResponse = unknown;

// FUNCTION
export const getProjectDetail = async (companiesId: number, projectId: number): Promise<TGetProjectDetailResponse> => {
  const endpoint = `/v1/companies/${companiesId}/projects/${projectId}/`;
  const response = await ApiService.get(endpoint);
  return response;
};
