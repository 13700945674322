import i18next from 'i18next';
import message from './message';

export const handleAPIError = (res: any, translateKey?: string, messageStr?: string): any => {
  if (messageStr) {
    message.error(`${i18next.t(translateKey)}: ${i18next.t(messageStr)}`);
  } else {
    const status = res.status;
    if (status === 200 || status === 201) {
      message.success(i18next.t(translateKey));
    } else if (res.data) {
      const keyError = Object.keys(res.data)[0];
      const errorMessages = res.data[keyError];
      if (status >= 500) {
        message.error(i18next.t('Error.generalSoftwareFailure'));
      } else if (status >= 300) {
        message.error(`${i18next.t(translateKey)}: ${errorMessages}`);
      } else if (status >= 200) {
        message.warning(`${i18next.t(translateKey)}: ${errorMessages}`);
      }
    } else {
      message.error(
        `${i18next.t(translateKey)}: ${res?.message ? res.message : i18next.t('Error.unableRetrieveDetail')}`,
      );
    }
  }
};
