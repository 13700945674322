import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { getListProjectAction } from '@/redux/actions/employee';
import { getListProject, TGetListProjectResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, refreshToken, TAuthCheckTokenResponse, TAuthRefreshTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION
export function* getListProjectSaga(action: ActionType<typeof getListProjectAction.request>): Generator {
  const { companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(getListProject, companyId, employeeId);
    const getListProjectResponse: TGetListProjectResponse = response as TGetListProjectResponse;
    yield put(getListProjectAction.success(getListProjectResponse));
    successCallback?.(getListProjectResponse);
  } catch (err) {
    handleAPIError(err, 'Error.listProjectByEmployeeError');
    yield put(getListProjectAction.failure(err));
    failedCallback?.(err);
  }
}
