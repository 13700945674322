import { createActionCreator } from 'deox';

import { TGetListEmployeeOutsideResponse } from '@/services/api';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListEmployeeOutsideAction {
  GET_LISTEMPLOYEEOUTSIDE = 'GET_LISTEMPLOYEEOUTSIDE',
  GET_LISTEMPLOYEEOUTSIDE_REQUEST = 'GET_LISTEMPLOYEEOUTSIDE_REQUEST',
  GET_LISTEMPLOYEEOUTSIDE_SUCCESS = 'GET_LISTEMPLOYEEOUTSIDE_SUCCESS',
  GET_LISTEMPLOYEEOUTSIDE_FAILED = 'GET_LISTEMPLOYEEOUTSIDE_FAILED',
}

// TYPES

export type TGetListEmployeeOutsideRequest = {
  type: EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TGetListEmployeeOutsideResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListEmployeeOutsideSuccess = {
  type: EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_SUCCESS;
  payload: { response: TGetListEmployeeOutsideResponse };
};

export type TGetListEmployeeOutsideFailed = { type: EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_FAILED };

// FUNCTION

export const getListEmployeeOutsideAction = {
  request: createActionCreator(
    EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TGetListEmployeeOutsideResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListEmployeeOutsideRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_SUCCESS,
    (resolve) =>
      (response: TGetListEmployeeOutsideResponse): TGetListEmployeeOutsideSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListEmployeeOutsideAction.GET_LISTEMPLOYEEOUTSIDE_FAILED,
    (resolve) =>
      (error: any): TGetListEmployeeOutsideFailed => {
        message.error('GET_LISTEMPLOYEEOUTSIDE_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
