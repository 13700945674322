import { all, fork } from 'redux-saga/effects';

import authSaga from './auth';
import projectDetailSaga from './project-detail';
import projectSettingSaga from './project-setting';
import projectExportSaga from './project-export';
import termConditionSaga from './general';
import projectSaga from './project';
import employeeSaga from './employee';
import companySaga from './company';
import locationSaga from './location';
import projectImportSaga from './project-import';

const rootSaga = function* root(): Generator {
  yield all([
    fork(authSaga),
    fork(projectDetailSaga),
    fork(projectSettingSaga),
    fork(projectSaga),
    fork(projectExportSaga),
    fork(termConditionSaga),
    fork(employeeSaga),
    fork(companySaga),
    fork(locationSaga),
    fork(projectImportSaga),
  ]);
};

export default rootSaga;
