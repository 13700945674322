import ApiService from '@/services/api';

// TYPES
export type TAssignUsersListResponse = unknown;
export type TAssignUsersListBody = {
  user_ids: number[];
};

export const assignUsersList = async (
  requestBody: TAssignUsersListBody,
  companyId: number,
  projectId: number,
): Promise<TAssignUsersListResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/assign-users/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
