import { createActionCreator } from 'deox';
import { TGetUnassignProjectResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EGetUnassignProjectAction {
  GET_UNASSIGN_PROJECT = 'GET_UNASSIGN_PROJECT',
  GET_UNASSIGN_PROJECT_REQUEST = 'GET_UNASSIGN_PROJECT_REQUEST',
  GET_UNASSIGN_PROJECT_SUCCESS = 'GET_UNASSIGN_PROJECT_SUCCESS',
  GET_UNASSIGN_PROJECT_FAILED = 'GET_UNASSIGN_PROJECT_FAILED',
}

// TYPES

export type TGetUnassignProjectRequest = {
  type: EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_REQUEST;
  payload: {
    companyId: number;
    employeeId: number;
    successCallback?: (response: TGetUnassignProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUnassignProjectSuccess = {
  type: EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_SUCCESS;
  payload: { response: TGetUnassignProjectResponse };
};

export type TGetUnassignProjectFailed = { type: EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_FAILED };

// FUNCTION

export const getUnassignProjectAction = {
  request: createActionCreator(
    EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_REQUEST,
    (resolve) =>
      (
        companyId: number,
        employeeId: number,
        successCallback?: (response: TGetUnassignProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUnassignProjectRequest => {
        return resolve({ companyId, employeeId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_SUCCESS,
    (resolve) =>
      (response: TGetUnassignProjectResponse): TGetUnassignProjectSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetUnassignProjectAction.GET_UNASSIGN_PROJECT_FAILED,
    (resolve) =>
      (error: any): TGetUnassignProjectFailed => {
        message.error('GET_LIST_PROJECT_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
