import cookie from 'react-cookies';
import env from '@/env';
import { TUserInfo } from '@/services/api/auth/get-user-info';

const COOKIE_DOMAIN = env.cookie.domain;
const COOKIE_ACCESS_TOKEN = 'atk';
const COOKIE_REFRESH_TOKEN = 'rfk';
const COOKIE_REFRESH_TOKEN_TIME = 'rfk_time';
const COOKIE_USER_INFO = 'user_info';
const cookieSetting = {
  path: '/',
  domain: COOKIE_DOMAIN,
};

const setCookie = (name: string, value: any, maxAge?: number): void => {
  cookie.save(name, value, {
    path: '/',
    domain: COOKIE_DOMAIN,
    maxAge: maxAge,
  });
};

const getCookie = (name: string): string => cookie.load(name);
const removeCookie = (name: string): void => cookie.remove(name, cookieSetting);

export const updateUserFinalRole = (userInfo: TUserInfo): TUserInfo => {
  // Extract role and licenseStatus from userInfo
  const userRole = userInfo.role;
  const licenseStatus = null;

  // Determine and update the new role based on license status and current role
  let newRole = userRole;

  // Check license status and role to decide on the new role
  if (['expired', 'disable', 'blocked'].includes(licenseStatus) && ['Member', 'Manager'].includes(userRole)) {
    newRole = 'Guest';
  } else if (licenseStatus === 'blocked' && userRole === 'Admin') {
    newRole = 'Guest';
  }

  // Update userInfo object with the new role
  const updatedUserInfo = {
    ...userInfo,
    finalRole: newRole,
  };

  return updatedUserInfo;
};

class Helpers {
  getAccessToken = (): string => getCookie(COOKIE_ACCESS_TOKEN);

  storeAccessToken = (accessToken: string): void => setCookie(COOKIE_ACCESS_TOKEN, accessToken);

  getRefreshToken = (): string => getCookie(COOKIE_REFRESH_TOKEN);

  storeRefreshToken = (refreshToken: string, expiredTime: number): void =>
    setCookie(COOKIE_REFRESH_TOKEN, refreshToken, expiredTime);

  getRefreshTokenTime = (): string => getCookie(COOKIE_REFRESH_TOKEN_TIME);

  storeRefreshTokenTime = (refreshTokenTime: string): void => setCookie(COOKIE_REFRESH_TOKEN_TIME, refreshTokenTime);

  clearTokens = (): void => {
    removeCookie(COOKIE_ACCESS_TOKEN);
    removeCookie(COOKIE_REFRESH_TOKEN);
  };

  getUserInfo = (): any => {
    const userInfo: any = getCookie(COOKIE_USER_INFO);
    return userInfo;
  };

  storeUserInfo = (userInfo: TUserInfo): void => {
    const userInfoString = JSON.stringify(userInfo);
    setCookie(COOKIE_USER_INFO, userInfoString);
  };

  // New function to remove user information
  removeUserInfo = (): void => {
    removeCookie(COOKIE_USER_INFO);
  };
}

const instance = new Helpers();
export default instance;
