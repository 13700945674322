import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { projectImportFileAction } from '@/redux/actions/project-import';
import { TProjectImportFileResponse, projectImportFile } from '@/services/api/project-import';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, refreshToken, TAuthCheckTokenResponse, TAuthRefreshTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* projectImportFileSaga(action: ActionType<typeof projectImportFileAction.request>): Generator {
  const { materials, companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(projectImportFile, materials, companyId, projectId);
    const projectImportFileResponse: TProjectImportFileResponse = response as TProjectImportFileResponse;
    yield put(projectImportFileAction.success(projectImportFileResponse));
    successCallback?.(projectImportFileResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getProjectPDFFileError');
    yield put(projectImportFileAction.failure(err));
    failedCallback?.(err);
  }
}
