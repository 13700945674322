import ApiService from '@/services/api';
import { AxiosRequestConfig } from 'axios';

// TYPES
export type TDeleteProjectResponse = unknown;
export type TDeleteProjectBody = {
  project_ids: number[];
};

// FUNCTION
export const deleteProject = async (
  requestBody: TDeleteProjectBody,
  companyId: number,
): Promise<TDeleteProjectResponse> => {
  // Custom the delete method to add the request body in
  const params: AxiosRequestConfig = {
    method: 'delete',
    url: `/v1/companies/${companyId}/projects/delete-multi-projects/`,
    data: requestBody,
  };
  const response = await ApiService(params);
  return response;
};
