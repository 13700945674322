import ApiService from '@/services/api';

// TYPES
export type TResendOtpResponse = unknown;
export type TResendOtpBody = {
  email: string;
};

// FUNCTION
const endpoint = `/v1/otp/resend-otp/`;
export const resendOtp = async (requestBody: TResendOtpBody): Promise<TResendOtpResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
