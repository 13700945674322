import ApiService from '@/services/api';

// TYPES
export type TUnAssignUsersListResponse = unknown;
export type TUnAssignUsersListBody = {
  user_ids: number[];
};

// FUNCTION
export const unAssignUsersList = async (
  requestBody: TUnAssignUsersListBody,
  companyId: number,
  projectId: number,
): Promise<TUnAssignUsersListResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/unassign-users/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
