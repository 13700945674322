import { createActionCreator } from 'deox';

import { TProjectExportPdfResponse } from '@/services/api/project-export';
import message from '@/utils/message';

// CONSTANTS

export enum EProjectExportPdfAction {
  PROJECT_EXPORT_PDF = 'PROJECT_EXPORT_PDF',
  PROJECT_EXPORT_PDF_REQUEST = 'PROJECT_EXPORT_PDF_REQUEST',
  PROJECT_EXPORT_PDF_SUCCESS = 'PROJECT_EXPORT_PDF_SUCCESS',
  PROJECT_EXPORT_PDF_FAILED = 'PROJECT_EXPORT_PDF_FAILED',
}

// TYPES

export type TProjectExportPdfRequest = {
  type: EProjectExportPdfAction.PROJECT_EXPORT_PDF_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TProjectExportPdfResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TProjectExportPdfSuccess = {
  type: EProjectExportPdfAction.PROJECT_EXPORT_PDF_SUCCESS;
  payload: { response: TProjectExportPdfResponse };
};

export type TProjectExportPdfFailed = { type: EProjectExportPdfAction.PROJECT_EXPORT_PDF_FAILED };

// FUNCTION

export const projectExportPdfAction = {
  request: createActionCreator(
    EProjectExportPdfAction.PROJECT_EXPORT_PDF_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TProjectExportPdfResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TProjectExportPdfRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EProjectExportPdfAction.PROJECT_EXPORT_PDF_SUCCESS,
    (resolve) =>
      (response: TProjectExportPdfResponse): TProjectExportPdfSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EProjectExportPdfAction.PROJECT_EXPORT_PDF_FAILED,
    (resolve) =>
      (error: any): TProjectExportPdfFailed => {
        message.error('PROJECT_EXPORT_PDF_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
