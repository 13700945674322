import { createActionCreator } from 'deox';

import { TAssignProjectListBody, TAssignProjectListResponse } from '@/services/api/employee';

// CONSTANTS

export enum EAssignProjectListAction {
  ASSIGN_PROJECT_LIST = 'ASSIGN_PROJECT_LIST',
  ASSIGN_PROJECT_LIST_REQUEST = 'ASSIGN_PROJECT_LIST_REQUEST',
  ASSIGN_PROJECT_LIST_SUCCESS = 'ASSIGN_PROJECT_LIST_SUCCESS',
  ASSIGN_PROJECT_LIST_FAILED = 'ASSIGN_PROJECT_LIST_FAILED',
}

// TYPES

export type TAssignProjectListRequest = {
  type: EAssignProjectListAction.ASSIGN_PROJECT_LIST_REQUEST;
  payload: {
    materials: TAssignProjectListBody;
    companyId: number;
    employeeId: number;
    successCallback?: (response: TAssignProjectListResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAssignProjectListSuccess = {
  type: EAssignProjectListAction.ASSIGN_PROJECT_LIST_SUCCESS;
  payload: { response: TAssignProjectListResponse };
};

export type TAssignProjectListFailed = { type: EAssignProjectListAction.ASSIGN_PROJECT_LIST_FAILED };

// FUNCTION

export const assignProjectListAction = {
  request: createActionCreator(
    EAssignProjectListAction.ASSIGN_PROJECT_LIST_REQUEST,
    (resolve) =>
      (
        materials: TAssignProjectListBody,
        companyId: number,
        employeeId: number,
        successCallback?: (response: TAssignProjectListResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAssignProjectListRequest =>
        resolve({ materials, companyId, employeeId, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAssignProjectListAction.ASSIGN_PROJECT_LIST_SUCCESS,
    (resolve) =>
      (response: TAssignProjectListResponse): TAssignProjectListSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAssignProjectListAction.ASSIGN_PROJECT_LIST_FAILED,
    (resolve) =>
      (error: any): TAssignProjectListFailed =>
        resolve({ error }),
  ),
};
