import ApiService from '@/services/api';

// TYPES
export type TAuthSignUpBody = {
  full_name: string;
  phone: string;
  password: string;
  confirmPassword: string;
  email: string;
  company: any;
};
export type TAuthSignUpResponse = unknown;

// FUNCTION
const endpoint = `/v1/users/register/`;
export const authSignUp = async (requestBody: TAuthSignUpBody): Promise<TAuthSignUpResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
