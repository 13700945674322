import ApiService from '@/services/api';

// TYPES
export type TProjectImportFileResponse = unknown;
export type TProjectImportFileBody = {
  file: any;
};

// FUNCTION
export const projectImportFile = async (
  requestBody: TProjectImportFileBody,
  companyId: number,
  projectId: number,
): Promise<TProjectImportFileResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/import-file/`;
  const response = await ApiService.post(endpoint, requestBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};
