import ApiService from '@/services/api';

// TYPES
export type TCopyProjectResponse = unknown;
export type TCopyProjectBody = {
  project_ids: number[];
};

// FUNCTION
export const copyProject = async (requestBody: TCopyProjectBody, companyId: number): Promise<TCopyProjectResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/copy-multi-projects/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
