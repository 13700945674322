import { all, takeLatest } from 'redux-saga/effects';

import { getListEmployeeInsideAction } from '@/redux/actions/employee';
import { getListEmployeeAction } from '@/redux/actions/employee';
import { assignUsersListAction } from '@/redux/actions/employee';
import { assignProjectListAction } from '@/redux/actions/employee';
import { unAssignUsersListAction } from '@/redux/actions/employee';
import { addListEmployeeAction } from '@/redux/actions/employee';
import { deleteListEmployeeAction } from '@/redux/actions/employee';
import { getListProjectAction } from '@/redux/actions/employee';
import { getEmployeeInfoAction } from '@/redux/actions/employee';
import { updateProfileEmployeeAction } from '@/redux/actions/employee';
import { updateRoleEmployeeAction } from '@/redux/actions/employee';
import { getListEmployeeOutsideAction } from '@/redux/actions/employee';
import { getUnassignProjectAction } from '@/redux/actions/employee/get-unassign-project';
import { unassignProjectAction } from '@/redux/actions/employee';

import { getListEmployeeInsideSaga } from './get-list-employee-inside';
import { getListEmployeeSaga } from './get-list-employee';
import { assignUsersListSaga } from './assign-users-list';
import { assignProjectListSaga } from './assign-project-list';
import { unAssignUsersListSaga } from './unassign-users-list';
import { addListEmployeeSaga } from './add-list-employee';
import { deleteListEmployeeSaga } from './delete-list-employee';
import { getListProjectSaga } from './get-list-project';
import { getEmployeeInfoSaga } from './get-employee-info';
import { updateProfileEmployeeSaga } from './update-profile-employee';
import { updateRoleEmployeeSaga } from './update-employee-role';
import { getListEmployeeOutsideSaga } from './get-list-employee-outside';
import { getUnassignProjectSaga } from './get-unassign-project';
import { postUnassignProjectSaga } from './unasign-project-list';

export default function* root(): Generator {
  yield all([takeLatest(getListEmployeeInsideAction.request.type, getListEmployeeInsideSaga)]);
  yield all([takeLatest(getListEmployeeAction.request.type, getListEmployeeSaga)]);
  yield all([takeLatest(assignUsersListAction.request.type, assignUsersListSaga)]);
  yield all([takeLatest(unAssignUsersListAction.request.type, unAssignUsersListSaga)]);
  yield all([takeLatest(addListEmployeeAction.request.type, addListEmployeeSaga)]);
  yield all([takeLatest(deleteListEmployeeAction.request.type, deleteListEmployeeSaga)]);
  yield all([takeLatest(getListProjectAction.request.type, getListProjectSaga)]);
  yield all([takeLatest(assignProjectListAction.request.type, assignProjectListSaga)]);
  yield all([takeLatest(getEmployeeInfoAction.request.type, getEmployeeInfoSaga)]);
  yield all([takeLatest(updateProfileEmployeeAction.request.type, updateProfileEmployeeSaga)]);
  yield all([takeLatest(updateRoleEmployeeAction.request.type, updateRoleEmployeeSaga)]);
  yield all([takeLatest(getListEmployeeOutsideAction.request.type, getListEmployeeOutsideSaga)]);
  yield all([takeLatest(getUnassignProjectAction.request.type, getUnassignProjectSaga)]);
  yield all([takeLatest(unassignProjectAction.request.type, postUnassignProjectSaga)]);
}
