import { createReducer } from 'deox';

import {
  TAuthLoginResponse,
  TAuthLogoutResponse,
  TAuthSignUpResponse,
  TSendMailResponse,
  TResetPasswordResponse,
  TChangePasswordResponse,
  TUpdateProfileResponse,
  TUpdateUserResponse,
  TUserInfo,
  TVerifyOtpResponse,
  TResendOtpResponse,
  TRequestVerifyUserEmailResponse,
} from '@/services/api/auth';
import {
  authLoginAction,
  authLogoutAction,
  authSignUpAction,
  sendMailAction,
  resetPasswordAction,
  changePasswordAction,
  updateProfileAction,
  updateUserAction,
  getUserInfoAction,
  verifyOtpAction,
  resendOtpAction,
  requestVerifyUserEmailAction,
} from '@/redux/actions';
import { authLoginUpdateState } from './auth-login';
import { authLogoutUpdateState } from './auth-logout';
import { authSignupUpdateState } from './signup';
import { sendMailUpdateState } from './send-mail';
import { resetPasswordUpdateState } from './reset-password';
import { changePasswordUpdateState } from './change-password';
import { updateProfileUpdateState } from './update-profile';
import { updateUserUpdateState } from './update-user';
import { getUserInfoUpdateState } from './get-user-info';
import { verifyOtpUpdateState } from './verify-otp';
import { resendOtpUpdateState } from './resend-otp';
import { requestVerifyUserEmailUpdateState } from './request-verify-user-email';
import AuthHelpers from '@/services/helpers';

export type TAuthState = {
  authLoginResponse?: TAuthLoginResponse;
  authLogoutResponse?: TAuthLogoutResponse;
  authSignUpResponse?: TAuthSignUpResponse;
  sendMailResponse?: TSendMailResponse;
  resetPasswordResponse?: TResetPasswordResponse;
  changePasswordResponse?: TChangePasswordResponse;
  updateProfileResponse?: TUpdateProfileResponse;
  updateUserResponse?: TUpdateUserResponse;
  getUserInfoResponse?: TUserInfo;
  verifyOtpResponse?: TVerifyOtpResponse;
  resendOtpResponse?: TResendOtpResponse;
  requestVerifyUserEmailResponse?: TRequestVerifyUserEmailResponse;
};

const initialState: TAuthState = {
  authLoginResponse: undefined,
  authLogoutResponse: undefined,
  authSignUpResponse: undefined,
  sendMailResponse: undefined,
  resetPasswordResponse: undefined,
  changePasswordResponse: undefined,
  updateProfileResponse: undefined,
  updateUserResponse: undefined,
  verifyOtpResponse: undefined,
  resendOtpResponse: undefined,
  requestVerifyUserEmailResponse: undefined,
  getUserInfoResponse: {
    'id': null,
    'full_name': null,
    'email': null,
    'role': null,
    'company': {
      'id': null,
      'name': null,
      'email': null,
      'phone': null,
      'fax': null,
      'location': null,
      'address': null,
      'postal_code': null,
      'logo': null,
    },
  },
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(authLoginAction.success, authLoginUpdateState),
  handleAction(authLogoutAction.success, authLogoutUpdateState),
  handleAction(authSignUpAction.success, authSignupUpdateState),
  handleAction(sendMailAction.success, sendMailUpdateState),
  handleAction(resetPasswordAction.success, resetPasswordUpdateState),
  handleAction(changePasswordAction.success, changePasswordUpdateState),
  handleAction(updateProfileAction.success, updateProfileUpdateState),
  handleAction(updateUserAction.success, updateUserUpdateState),
  handleAction(getUserInfoAction.success, getUserInfoUpdateState),
  handleAction(verifyOtpAction.success, verifyOtpUpdateState),
  handleAction(resendOtpAction.success, resendOtpUpdateState),
  handleAction(requestVerifyUserEmailAction.success, requestVerifyUserEmailUpdateState),
]);

export default AuthReducer;
