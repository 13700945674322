import { createActionCreator } from 'deox';
import { TSaveProjectDetailBody, TSaveProjectDetailResponse } from '@/services/api/project-detail';
import message from '@/utils/message';

// CONSTANTS

export enum ESaveProjectDetailAction {
  SAVE_PROJECTDETAIL = 'SAVE_PROJECTDETAIL',
  SAVE_PROJECTDETAIL_REQUEST = 'SAVE_PROJECTDETAIL_REQUEST',
  SAVE_PROJECTDETAIL_SUCCESS = 'SAVE_PROJECTDETAIL_SUCCESS',
  SAVE_PROJECTDETAIL_FAILED = 'SAVE_PROJECTDETAIL_FAILED',
}

// TYPES

export type TSaveProjectDetailRequest = {
  type: ESaveProjectDetailAction.SAVE_PROJECTDETAIL_REQUEST;
  payload: {
    materials: TSaveProjectDetailBody;
    companyId: number;
    projectId: number;
    successCallback?: (response: TSaveProjectDetailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSaveProjectDetailSuccess = {
  type: ESaveProjectDetailAction.SAVE_PROJECTDETAIL_SUCCESS;
  payload: { response: TSaveProjectDetailResponse };
};

export type TSaveProjectDetailFailed = { type: ESaveProjectDetailAction.SAVE_PROJECTDETAIL_FAILED };

// FUNCTION

export const saveProjectDetailAction = {
  request: createActionCreator(
    ESaveProjectDetailAction.SAVE_PROJECTDETAIL_REQUEST,
    (resolve) =>
      (
        materials: TSaveProjectDetailBody,
        companyId: number,
        projectId: number,
        successCallback?: (response: TSaveProjectDetailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSaveProjectDetailRequest => {
        return resolve({ materials, companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ESaveProjectDetailAction.SAVE_PROJECTDETAIL_SUCCESS,
    (resolve) =>
      (response: TSaveProjectDetailResponse): TSaveProjectDetailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    ESaveProjectDetailAction.SAVE_PROJECTDETAIL_FAILED,
    (resolve) =>
      (error: any): TSaveProjectDetailFailed => {
        message.error('POST_PROJECTDETAIL_FAILED Action', error.data.status_message);
        return resolve({ error });
      },
  ),
};
