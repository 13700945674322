import ApiService from '@/services/api';
// TYPES
export type TAuthCheckTokenResponse = any;

// FUNCTION
const endpoint = `/api/token/check/`;
export const checkAccessToken = async (): Promise<TAuthCheckTokenResponse> => {
  const response = await ApiService.get(endpoint);
  return response;
};
