import ApiService from '@/services/api';

// TYPES
export type TAuthLoginBody = {
  email: string;
  password: string;
  remember_me: boolean;
};
export type TAuthLoginResponse = any;

// FUNCTION
const endpoint = `/v1/auth/login/`;
export const authLogin = async (requestBody: TAuthLoginBody): Promise<TAuthLoginResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
