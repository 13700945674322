import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES
export type TGetUserInfoResponse = any;
export type TUserInfo = {
  id: number;
  full_name: string;
  email: string;
  role?: string;
  company?: {
    id?: number;
    name: string;
    email: string;
    phone: string;
    fax?: string;
    location?: string;
    address: string;
    postal_code?: string;
    logo?: string;
  };
};

export const getUserInfo = async (): Promise<TGetUserInfoResponse> => {
  try {
    const endpoint = `/v1/user/me/`;
    const response = await ApiService.get(endpoint);
    return response;
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response;
  }
};
