import ApiService from '@/services/api';

// TYPES
export type TGetListProjectResponse = unknown;

// FUNCTION
export const getListProject = async (companyId: number, employeeId: number): Promise<TGetListProjectResponse> => {
  const response = await ApiService.get(`/v1/companies/${companyId}/employees/${employeeId}/assigned-projects/`);
  return response;
};
