import { createActionCreator } from 'deox';
import { TPostProjectDetailResponse } from '@/services/api/project-detail';

// CONSTANTS

export enum EPostProjectDetailAction {
  POST_PROJECTDETAIL = 'POST_PROJECTDETAIL',
  POST_PROJECTDETAIL_REQUEST = 'POST_PROJECTDETAIL_REQUEST',
  POST_PROJECTDETAIL_SUCCESS = 'POST_PROJECTDETAIL_SUCCESS',
  POST_PROJECTDETAIL_FAILED = 'POST_PROJECTDETAIL_FAILED',
}

// TYPES

export type TPostProjectDetailRequest = {
  type: EPostProjectDetailAction.POST_PROJECTDETAIL_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TPostProjectDetailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostProjectDetailSuccess = {
  type: EPostProjectDetailAction.POST_PROJECTDETAIL_SUCCESS;
  payload: { response: TPostProjectDetailResponse };
};

export type TPostProjectDetailFailed = { type: EPostProjectDetailAction.POST_PROJECTDETAIL_FAILED };

// FUNCTION

export const postProjectDetailAction = {
  request: createActionCreator(
    EPostProjectDetailAction.POST_PROJECTDETAIL_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TPostProjectDetailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostProjectDetailRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostProjectDetailAction.POST_PROJECTDETAIL_SUCCESS,
    (resolve) =>
      (response: TPostProjectDetailResponse): TPostProjectDetailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostProjectDetailAction.POST_PROJECTDETAIL_FAILED,
    (resolve) =>
      (error: any): TPostProjectDetailFailed => {
        // message.error('POST_PROJECTDETAIL_FAILED Action', error.data.status_message);
        return resolve({ error });
      },
  ),
};
