import ApiService from '@/services/api';

// TYPES
export type TUpdateRoleEmployeeResponse = unknown;
export type TUpdateRoleEmployeeBody = {
  role: string;
};

// FUNCTION
export const updateRoleEmployee = async (
  requestBody: TUpdateRoleEmployeeBody,
  companyId: number,
  employeeId: number,
): Promise<TUpdateRoleEmployeeResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/${employeeId}/update-role/`;
  const response = await ApiService.patch(endpoint, requestBody);
  return response;
};
