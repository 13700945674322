import ApiService from '@/services/api';

// TYPES
export type TVerifyOtpBody = {
  email: string;
  otp: string;
};
export type TVerifyOtpResponse = unknown;

// FUNCTION
const endpoint = `/v1/otp/verify-otp/`;
export const verifyOtp = async (requestBody: TVerifyOtpBody): Promise<TVerifyOtpResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
