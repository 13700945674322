import { createActionCreator } from 'deox';

import { TProjectExportXmlResponse } from '@/services/api/project-export';
import message from '@/utils/message';

// CONSTANTS

export enum EProjectExportXmlAction {
  PROJECT_EXPORT_XML = 'PROJECT_EXPORT_XML',
  PROJECT_EXPORT_XML_REQUEST = 'PROJECT_EXPORT_XML_REQUEST',
  PROJECT_EXPORT_XML_SUCCESS = 'PROJECT_EXPORT_XML_SUCCESS',
  PROJECT_EXPORT_XML_FAILED = 'PROJECT_EXPORT_XML_FAILED',
}

// TYPES

export type TProjectExportXmlRequest = {
  type: EProjectExportXmlAction.PROJECT_EXPORT_XML_REQUEST;
  payload: {
    companyId: number;
    projectId: number;
    successCallback?: (response: TProjectExportXmlResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TProjectExportXmlSuccess = {
  type: EProjectExportXmlAction.PROJECT_EXPORT_XML_SUCCESS;
  payload: { response: TProjectExportXmlResponse };
};

export type TProjectExportXmlFailed = { type: EProjectExportXmlAction.PROJECT_EXPORT_XML_FAILED };

// FUNCTION

export const projectExportXmlAction = {
  request: createActionCreator(
    EProjectExportXmlAction.PROJECT_EXPORT_XML_REQUEST,
    (resolve) =>
      (
        companyId: number,
        projectId: number,
        successCallback?: (response: TProjectExportXmlResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TProjectExportXmlRequest => {
        return resolve({ companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EProjectExportXmlAction.PROJECT_EXPORT_XML_SUCCESS,
    (resolve) =>
      (response: TProjectExportXmlResponse): TProjectExportXmlSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EProjectExportXmlAction.PROJECT_EXPORT_XML_FAILED,
    (resolve) =>
      (error: any): TProjectExportXmlFailed => {
        message.error('PROJECT_EXPORT_XML_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
