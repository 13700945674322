import ApiService from '@/services/api';

// TYPES
export type TPostProjectResponse = unknown;

// FUNCTION
export const postProject = async (companyId: number): Promise<TPostProjectResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/`;
  const response = await ApiService.get(endpoint);
  return response;
};
