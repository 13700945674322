import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';

import Helpers from './helpers';

const AuthorizedInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    validateStatus: (status) => {
      return status <= 500;
    },
  });

  const onRequest = async (request: AxiosRequestConfig): Promise<any> => {
    const authBearer = Helpers.getAccessToken();
    if (authBearer) {
      request.headers.Authorization = `Bearer ${authBearer}`;
    }
    return request;
  };

  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    const statusCode = response.data.status_code;
    const statusMessage = response.data.status_message;
    if (statusCode === 0 || statusMessage?.startsWith('Error')) {
      throw new axios.AxiosError(statusMessage, undefined, response.config, response.request, response);
    } else {
      return response;
    }
  };

  const onResponseError = async (axiosError: any): Promise<void | AxiosResponse<any>> => {
    return Promise.reject(axiosError);
  };

  instance.interceptors.request.use(onRequest, (error: AxiosError) => onResponseError(error));
  instance.interceptors.response.use(onResponseSuccess, onResponseError);
  return instance;
};

export default AuthorizedInstance;
