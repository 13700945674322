import ApiService from '@/services/api';

// TYPES
export type TGetListEmployeeOutsideResponse = unknown;

// FUNCTION
export const getListEmployeeOutside = async (
  companyId: number,
  projectId: number,
): Promise<TGetListEmployeeOutsideResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/unassigned-users/`;
  const response = await ApiService.get(endpoint);
  return response;
};
