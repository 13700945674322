import ApiService from '@/services/api';

// TYPES
export type TGetUnassignProjectResponse = unknown;

// FUNCTION
export const getUnassignProject = async (
  companyId: number,
  employeeId: number,
): Promise<TGetUnassignProjectResponse> => {
  const response = await ApiService.get(`/v1/companies/${companyId}/employees/${employeeId}/unassigned-projects/`);
  return response;
};
