import ApiService from '@/services/api';

// TYPES
export type TResetPasswordResponse = unknown;
export type TResetPasswordBody = {
  email: string;
  password: string;
  otp: string;
};

const endpoint = `/v1/otp/reset-password/`;
export const resetPassword = async (requestBody: TResetPasswordBody): Promise<TResetPasswordResponse> => {
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
