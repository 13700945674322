import ApiService from '@/services/api';

// TYPES
export type TCreateProjectResponse = unknown;
export type TCreateProjectBody = {
  name: string;
};

// FUNCTION
export const createProject = async (
  requestBody: TCreateProjectBody,
  companyId: number,
): Promise<TCreateProjectResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
