import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { getUnassignProjectAction } from '@/redux/actions';
import { getUnassignProject, TGetUnassignProjectResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, refreshToken, TAuthCheckTokenResponse, TAuthRefreshTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION
export function* getUnassignProjectSaga(action: ActionType<typeof getUnassignProjectAction.request>): Generator {
  const { companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(getUnassignProject, companyId, employeeId);
    const getUnassignProjectResponse: TGetUnassignProjectResponse = response as TGetUnassignProjectResponse;
    yield put(getUnassignProjectAction.success(getUnassignProjectResponse));
    successCallback?.(getUnassignProjectResponse);
  } catch (err) {
    handleAPIError(err, 'Error.listProjectByEmployeeError');
    yield put(getUnassignProjectAction.failure(err));
    failedCallback?.(err);
  }
}
