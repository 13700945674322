import { createActionCreator } from 'deox';
import { TUpdateRoleEmployeeBody, TUpdateRoleEmployeeResponse } from '@/services/api/employee/update-employee-role';

// CONSTANTS

export enum EUpdateRoleEmployeeAction {
  UPDATE_ROLE_EMPLOYEE = 'UPDATE_ROLE_EMPLOYEE',
  UPDATE_ROLE_EMPLOYEE_REQUEST = 'UPDATE_ROLE_EMPLOYEE_REQUEST',
  UPDATE_ROLE_EMPLOYEE_SUCCESS = 'UPDATE_ROLE_EMPLOYEE_SUCCESS',
  UPDATE_ROLE_EMPLOYEE_FAILED = 'UPDATE_ROLE_EMPLOYEE_FAILED',
}

// TYPES

export type TUpdateRoleEmployeeRequest = {
  type: EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_REQUEST;
  payload: {
    materials: TUpdateRoleEmployeeBody;
    companyId: number;
    employeeId: number;
    successCallback?: (response: TUpdateRoleEmployeeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRoleEmployeeSuccess = {
  type: EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_SUCCESS;
  payload: { response: TUpdateRoleEmployeeResponse };
};

export type TUpdateRoleEmployeeFailed = { type: EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_FAILED };

// FUNCTION

export const updateRoleEmployeeAction = {
  request: createActionCreator(
    EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRoleEmployeeBody,
        companyId: number,
        employeeId: number,
        successCallback?: (response: TUpdateRoleEmployeeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRoleEmployeeRequest =>
        resolve({ materials, companyId, employeeId, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_SUCCESS,
    (resolve) =>
      (response: TUpdateRoleEmployeeResponse): TUpdateRoleEmployeeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRoleEmployeeAction.UPDATE_ROLE_EMPLOYEE_FAILED,
    (resolve) =>
      (error: any): TUpdateRoleEmployeeFailed =>
        resolve({ error }),
  ),
};
