import ApiService from '@/services/api';

// TYPES
export type TGetEmployeeInfoResponse = unknown;

export const getEmployeeInfo = async (companyId: number, employeeId: number): Promise<TGetEmployeeInfoResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/${employeeId}/`;
  const response = await ApiService.get(endpoint);
  return response;
};
