import ApiService from '@/services/api';

// TYPES
export type TSaveProjectDetailBody = {
  piles: any[];
  soil_profiles: any[];
  horizontal_loadcases: any[];
};
export type TSaveProjectDetailResponse = any;

// FUNCTION
export const saveProjectDetail = async (
  requestBody: TSaveProjectDetailBody,
  companyId: number,
  projectId: number,
): Promise<TSaveProjectDetailResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/update-table-datas/`;
  const response = await ApiService.put(endpoint, requestBody);
  return response;
};
