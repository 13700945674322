import ApiService from '@/services/api';

// TYPES
export type TGetListEmployeeResponse = unknown;

export const getListEmployee = async (companyId: number): Promise<TGetListEmployeeResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/`;
  const response = await ApiService.get(endpoint);
  return response;
};
