import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { updateRoleEmployeeAction } from '@/redux/actions/employee';
import { updateRoleEmployee, TUpdateRoleEmployeeResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, refreshToken, TAuthCheckTokenResponse, TAuthRefreshTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

export function* updateRoleEmployeeSaga(action: ActionType<typeof updateRoleEmployeeAction.request>): Generator {
  const { materials, companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(updateRoleEmployee, materials, companyId, employeeId);
    const updateProfileEmployeeResponse: TUpdateRoleEmployeeResponse = response as TUpdateRoleEmployeeResponse;
    yield put(updateRoleEmployeeAction.success(updateProfileEmployeeResponse));
    successCallback?.(updateProfileEmployeeResponse);
  } catch (err) {
    yield put(updateRoleEmployeeAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.editEmployeeInfoError');
  }
}
