import ApiService from '@/services/api';

// TYPES
export type TUnassignProjectResponse = unknown;
export type TUnassignProjectBody = {
  project_ids: number[];
};

// FUNCTION
export const postUnassignProject = async (
  requestBody: TUnassignProjectBody,
  companyId: number,
  employeeId: number,
): Promise<TUnassignProjectResponse> => {
  const response = await ApiService.post(
    `/v1/companies/${companyId}/employees/${employeeId}/unassign-projects/`,
    requestBody,
  );
  return response;
};
