import ApiService from '@/services/api';

// TYPES
export type TAssignProjectListResponse = unknown;
export type TAssignProjectListBody = {
  project_ids: number[];
};

export const assignProjectList = async (
  requestBody: TAssignProjectListBody,
  companyId: number,
  employeeId: number,
): Promise<TAssignProjectListResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/${employeeId}/assign-projects/`;
  const response = await ApiService.post(endpoint, requestBody);
  return response;
};
