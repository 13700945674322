import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { postProjectDetailAction } from '@/redux/actions/project-detail';
import { postProjectDetail, TPostProjectDetailResponse } from '@/services/api/project-detail';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, TAuthCheckTokenResponse } from '@/services/api/auth/check-token';
import { refreshToken, TAuthRefreshTokenResponse } from '@/services/api/auth/refresh-token';
import Helpers from '@/services/helpers';

// FUNCTION

export function* postProjectDetailSaga(action: ActionType<typeof postProjectDetailAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(postProjectDetail, companyId, projectId);
    const postProjectDetailResponse: TPostProjectDetailResponse = response as TPostProjectDetailResponse;
    yield put(postProjectDetailAction.success(postProjectDetailResponse));
    successCallback?.(postProjectDetailResponse);
  } catch (err) {
    yield put(postProjectDetailAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.calculateProjectError');
  }
}
