import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { getUserInfoAction } from '@/redux/actions/auth';
import {
  checkAccessToken,
  refreshToken,
  getUserInfo,
  TAuthCheckTokenResponse,
  TAuthRefreshTokenResponse,
  TGetUserInfoResponse,
  TUserInfo,
} from '@/services/api/auth';
import Helpers from '@/services/helpers';
import { handleAPIError } from '@/utils/handle-api-error';
import AuthHelpers from '@/services/helpers';

// FUNCTION

export function* getUserInfoSaga(action: ActionType<typeof getUserInfoAction.request>): Generator {
  const { successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const getUserResponse = (yield call(getUserInfo)) as unknown as TGetUserInfoResponse;
    if (getUserResponse.status === 200) {
      Helpers.storeUserInfo(getUserResponse.data);
      AuthHelpers.storeUserInfo(getUserResponse.data as TUserInfo);
    }
    yield put(getUserInfoAction.success(getUserResponse));
    successCallback?.(getUserResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getUserInfoError');
    yield put(getUserInfoAction.failure(err));
    failedCallback?.(err);
  }
}
