import ApiService from '@/services/api';

// TYPES
export type TProjectExportPdfResponse = unknown;

// FUNCTION
export const projectExportPdf = async (companyId: number, projectId: number): Promise<TProjectExportPdfResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/pdf/`;
  const response = await ApiService.get(endpoint);
  return response;
};
