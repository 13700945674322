import { createActionCreator } from 'deox';

import { TUpdateProfileEmployeeBody, TUpdateProfileEmployeeResponse } from '@/services/api/employee';

// CONSTANTS

export enum EUpdateProfileEmployeeAction {
  UPDATE_PROFILE_EMPLOYEE = 'UPDATE_PROFILE_EMPLOYEE',
  UPDATE_PROFILE_EMPLOYEE_REQUEST = 'UPDATE_PROFILE_EMPLOYEE_REQUEST',
  UPDATE_PROFILE_EMPLOYEE_SUCCESS = 'UPDATE_PROFILE_EMPLOYEE_SUCCESS',
  UPDATE_PROFILE_EMPLOYEE_FAILED = 'UPDATE_PROFILE_EMPLOYEE_FAILED',
}

// TYPES

export type TUpdateProfileEmployeeRequest = {
  type: EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_REQUEST;
  payload: {
    materials: TUpdateProfileEmployeeBody;
    companyId: number;
    employeeId: number;
    successCallback?: (response: TUpdateProfileEmployeeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateProfileEmployeeSuccess = {
  type: EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_SUCCESS;
  payload: { response: TUpdateProfileEmployeeResponse };
};

export type TUpdateProfileEmployeeFailed = { type: EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_FAILED };

// FUNCTION

export const updateProfileEmployeeAction = {
  request: createActionCreator(
    EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateProfileEmployeeBody,
        companyId: number,
        employeeId: number,
        successCallback?: (response: TUpdateProfileEmployeeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateProfileEmployeeRequest =>
        resolve({ materials, companyId, employeeId, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_SUCCESS,
    (resolve) =>
      (response: TUpdateProfileEmployeeResponse): TUpdateProfileEmployeeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateProfileEmployeeAction.UPDATE_PROFILE_EMPLOYEE_FAILED,
    (resolve) =>
      (error: any): TUpdateProfileEmployeeFailed =>
        resolve({ error }),
  ),
};
