import ApiService from '@/services/api';

// TYPES
export type TUpdateProfileBody = {
  name: string;
  address: string;
  location: string;
  postal_code: string;
  email: string;
  logo: string;
  phone: string;
  fax: string;
};
export type TUpdateProfileResponse = unknown;

// FUNCTION
export const updateProfile = async (
  requestBody: TUpdateProfileBody,
  companyId: number,
): Promise<TUpdateProfileResponse> => {
  const endpoint = `/v1/companies/${companyId}/`;
  const response = await ApiService.put(endpoint, requestBody);
  return response;
};
