import ApiService from '@/services/api';

// TYPES
export type TPostProjectSettingBody = {
  settings: any;
};
export type TPostProjectSettingResponse = unknown;

// FUNCTION
export const postProjectSetting = async (
  requestBody: TPostProjectSettingBody,
  companyId: number,
  projectId: number,
): Promise<TPostProjectSettingResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/update-settings/`;
  const response = await ApiService.put(endpoint, requestBody);
  return response;
};
