import ApiService from '@/services/api';

// TYPES
export type TGetListEmployeeInsideResponse = unknown;

// FUNCTION
export const getListEmployeeInside = async (
  companyId: number,
  projectId: number,
): Promise<TGetListEmployeeInsideResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/assigned-users/`;
  const response = await ApiService.get(endpoint);
  return response;
};
