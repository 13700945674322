import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { copyProjectAction } from '@/redux/actions/project';
import { copyProject, TCopyProjectResponse } from '@/services/api/project';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, TAuthCheckTokenResponse } from '@/services/api/auth/check-token';
import { refreshToken, TAuthRefreshTokenResponse } from '@/services/api/auth/refresh-token';
import Helpers from '@/services/helpers';

export function* copyProjectSaga(action: ActionType<typeof copyProjectAction.request>): Generator {
  const { materials, companyId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(copyProject, materials, companyId);
    const copyProjectResponse: TCopyProjectResponse = response as TCopyProjectResponse;
    yield put(copyProjectAction.success(copyProjectResponse));
    successCallback?.(copyProjectResponse);
  } catch (err) {
    yield put(copyProjectAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.copyProjectError');
  }
}
