import { createActionCreator } from 'deox';
import { TUnassignProjectResponse, TUnassignProjectBody } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EUnassignProjectAction {
  UNASSIGN_PROJECT = 'UNASSIGN_PROJECT',
  UNASSIGN_PROJECT_REQUEST = 'UNASSIGN_PROJECT_REQUEST',
  UNASSIGN_PROJECT_SUCCESS = 'UNASSIGN_PROJECT_SUCCESS',
  UNASSIGN_PROJECT_FAILED = 'UNASSIGN_PROJECT_FAILED',
}

// TYPES

export type TUnassignProjectRequest = {
  type: EUnassignProjectAction.UNASSIGN_PROJECT_REQUEST;
  payload: {
    materials: TUnassignProjectBody;
    companyId: number;
    employeeId: number;
    successCallback?: (response: TUnassignProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUnassignProjectSuccess = {
  type: EUnassignProjectAction.UNASSIGN_PROJECT_SUCCESS;
  payload: { response: TUnassignProjectResponse };
};

export type TUnassignProjectFailed = { type: EUnassignProjectAction.UNASSIGN_PROJECT_FAILED };

// FUNCTION

export const unassignProjectAction = {
  request: createActionCreator(
    EUnassignProjectAction.UNASSIGN_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TUnassignProjectBody,
        companyId: number,
        employeeId: number,
        successCallback?: (response: TUnassignProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUnassignProjectRequest => {
        return resolve({ materials, companyId, employeeId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EUnassignProjectAction.UNASSIGN_PROJECT_SUCCESS,
    (resolve) =>
      (response: TUnassignProjectResponse): TUnassignProjectSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EUnassignProjectAction.UNASSIGN_PROJECT_FAILED,
    (resolve) =>
      (error: any): TUnassignProjectFailed => {
        message.error('GET_LIST_PROJECT_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
