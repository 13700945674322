import ApiService from '@/services/api';

// TYPES
export type TDeleteListEmployeeResponse = unknown;

export const deleteListEmployee = async (
  companyId: number,
  employeeId: number,
): Promise<TDeleteListEmployeeResponse> => {
  const endpoint = `/v1/companies/${companyId}/employees/${employeeId}/delete/`;
  const response = await ApiService.delete(endpoint);
  return response;
};
