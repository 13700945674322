import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';
import { getListEmployeeInsideAction } from '@/redux/actions/employee';
import { getListEmployeeInside, TGetListEmployeeInsideResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';
import { checkAccessToken, refreshToken, TAuthCheckTokenResponse, TAuthRefreshTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* getListEmployeeInsideSaga(action: ActionType<typeof getListEmployeeInsideAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) {
        Helpers.storeAccessToken(refreshResponse.data.access_token);
      }
    }
    const response = yield call(getListEmployeeInside, companyId, projectId);
    const getListEmployeeInsideResponse: TGetListEmployeeInsideResponse = response as TGetListEmployeeInsideResponse;
    yield put(getListEmployeeInsideAction.success(getListEmployeeInsideResponse));
    successCallback?.(getListEmployeeInsideResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getListEmployeeInsideProjectError');
    yield put(getListEmployeeInsideAction.failure(err));
    failedCallback?.(err);
  }
}
