import ApiService from '@/services/api';

// TYPES
export type TProjectExportExcelResponse = unknown;

// FUNCTION
export const projectExportExcel = async (
  companyId: number,
  projectId: number,
): Promise<TProjectExportExcelResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/xlsx/`;
  const response = await ApiService.get(endpoint, { responseType: 'blob' });
  return response;
};
