import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { authLogoutAction } from '@/redux/actions';
import {
  authLogout,
  checkAccessToken,
  refreshToken,
  TAuthCheckTokenResponse,
  TAuthRefreshTokenResponse,
  TAuthLogoutResponse,
} from '@/services/api';
import { handleAPIError } from '@/utils/handle-api-error';
import Helpers from '@/services/helpers';

// FUNCTION
export function* authLogoutSaga(action: ActionType<typeof authLogoutAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const checkResponse = (yield call(checkAccessToken)) as unknown as TAuthCheckTokenResponse;
    if (checkResponse.status === 401) {
      const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
      if (refreshResponse.status === 200) Helpers.storeAccessToken(refreshResponse.data.access_token);
    }
    const response = yield call(authLogout, materials);
    const authLogoutResponse: TAuthLogoutResponse = response as TAuthLogoutResponse;
    yield put(authLogoutAction.success(authLogoutResponse));
    successCallback?.(authLogoutResponse);
  } catch (err) {
    yield put(authLogoutAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.logoutError');
  }
}
